import React, { FunctionComponent } from 'react'

type Props = {}
import 'normalize.css'
import '../styles/main.css'

const IndePage: FunctionComponent<Props> = ({}) => {
	return <div></div>
}

export default IndePage
